<template>
  <v-data-table
    :headers="tableHeaders"
    :items="tableItems"
    item-class="cssClass"
    :loading="loading"
    :loader-height="2"
    disable-pagination
    hide-default-footer
    class="d-data-table"
    dense
  >
  </v-data-table>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'


export default {
  props: {
    initialTreatment: Boolean
  },


  data () {
    return {
      loading: true
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId', 'shortDealerById']),
    ...mapGetters('report', ['servicesByDealerReport']),
    ...mapGetters('user', ['currentUser']),

    reportItems () {
      return this.servicesByDealerReport(this.initialTreatment)
    },

    tableHeaders () {
      const headers = []

      headers.push({ text: this.$t('report.servicesTable.headers.dealer'), value: 'dealer.name', cellClass: 'font-weight-medium' })

      const dates = this.reportItems.map(item => item.date)
      const uniqueDates = ([...new Set(dates)]).sort()

      for (const date of uniqueDates) {
        headers.push({ text: moment.utc(date).format('MMM YYYY'), value: date })
      }

      return headers
    },

    tableItems () {
      const items = {}

      for (const item of this.reportItems) {
        items[item.dealerId] = items[item.dealerId] || {}
        items[item.dealerId][item.date] = item.count
        items[item.dealerId].dealer = this.shortDealerById(item.dealerId) || {}
        items[item.dealerId].cssClass = item.dealerId === this.currentDealerId ? 'font-weight-medium active' : ''
      }

      return Object.values(items)
    }
  },


  methods: {
    ...mapActions('report', ['loadServicesByDealerReport'])
  },


  async created () {
    try {
      this.loading = true
      await this.loadServicesByDealerReport({ chainId: this.currentUser.chainId, initialTreatment: this.initialTreatment })
    } catch (error) {
      this.$log.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>
