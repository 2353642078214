<template>
  <div class="d-page-wrapper py-10">
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.activeCarsChartTitle')}}
            </v-card-title>
          <v-card-text>
            <div style="height:400px">
              <d-line-chart
                :chart-data="activeCarsChartData"
                :options="lineChartOptions"
              ></d-line-chart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.servicesChart.title')}}
          </v-card-title>
          <v-card-text>
            <div style="height:400px">
              <d-bar-chart
                :chart-data="servicesChartData"
                :options="barChartOptions"
              ></d-bar-chart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.notificationsSentChartTitle')}}
          </v-card-title>
          <v-card-text>
            <div style="height:400px">
              <d-line-chart
                :chart-data="notificationsSentChartData"
                :options="lineChartOptions"
              ></d-line-chart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.nextNotificationsChartTitle')}}
          </v-card-title>
          <v-card-text>
            <div style="height:400px">
              <d-line-chart
                :chart-data="nextNotificationsChartData"
                :options="lineChartOptions"
              ></d-line-chart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="currentUser.roleId === userRole.superAdmin" class="mt-8">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.servicesTable.initialTreatmentTitle') }}
          </v-card-title>
          <v-card-text>
            <services-table :initial-treatment="true"></services-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="text-subtitle-2 justify-center">
            {{ $t('report.servicesTable.notInitialTreatmentTitle') }}
          </v-card-title>
          <v-card-text>
            <services-table :initial-treatment="false"></services-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { userRole } from '@/constants'

import moment from 'moment'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'

import DBarChart from '@/components/DBarChart'
import DLineChart from '@/components/DLineChart'
import ServicesTable from './ServicesTable'


export default {
  components: {
    DBarChart,
    DLineChart,
    ServicesTable
  },


  mixins: [
    pageMixin,
    snackbarMixin
  ],


  data () {
    return {
      userRole,

      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              precision: 0
            }
          }]
        }
      },

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              precision: 0
            }
          }]
        }
      }
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('dealer', ['currentDealerId']),
    ...mapGetters('report', ['activeCarsReport', 'nextNotificationsReport', 'notificationsSentReport', 'servicesReport']),
    ...mapGetters('chain', ['currentChainId']),

    chainId () {
      return this.currentDealerId === 'all' ? '' : this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
    },

    dealerId () {
      return this.currentDealerId === 'all' ? '' : this.currentDealerId
    },

    activeCarsChartData () {
      const report = this.activeCarsReport(this.dealerId, this.chainId)
      const labels = report.map(item => moment.utc(item.date).format('MMM'))
      const data = report.map(item => item.count)
      const chartData = {
        labels,
        datasets: [
          {
            data,
            fill: false,
            borderColor: this.$vuetify.theme.themes.light.primary
          }
        ]
      }
      return chartData
    },

    nextNotificationsChartData () {
      const report = this.nextNotificationsReport(this.dealerId, this.chainId)
      const labels = report.map(item => moment.utc(item.date).format('MMM'))
      const data = report.map(item => item.count)
      const chartData = {
        labels,
        datasets: [
          {
            data,
            fill: false,
            borderColor: this.$vuetify.theme.themes.light['d-orange']
          }
        ]
      }
      return chartData
    },

    notificationsSentChartData () {
      const report = this.notificationsSentReport(this.dealerId, this.chainId)
      const labels = report.map(item => moment.utc(item.date).format('MMM'))
      const data = report.map(item => item.count)
      const chartData = {
        labels,
        datasets: [
          {
            data,
            fill: false,
            borderColor: this.$vuetify.theme.themes.light.primary
          }
        ]
      }
      return chartData
    },

    servicesChartData () {
      const initialTreatmentReport = this.servicesReport(this.dealerId, true, this.chainId)
      const notInitialTreatmentReport = this.servicesReport(this.dealerId, false, this.chainId)
      const labels = initialTreatmentReport.map(item => moment.utc(item.date).format('MMM'))
      const chartData = {
        labels,
        datasets: [
          {
            label: this.$t('report.servicesChart.initialTreatmentLabel'),
            data: initialTreatmentReport.map(item => item.count),
            backgroundColor: this.$vuetify.theme.themes.light['d-orange']
          },
          {
            label: this.$t('report.servicesChart.notInitialTreatmentLabel'),
            data: notInitialTreatmentReport.map(item => item.count),
            backgroundColor: this.$vuetify.theme.themes.light.primary
          }
        ]
      }
      return chartData
    }
  },


  watch: {
    currentDealerId () {
      this.loadReports()
    },
    currentChainId () {
      this.loadReports()
    }
  },


  methods: {
    ...mapActions('report', ['loadActiveCarsReport', 'loadNextNotificationsReport', 'loadNotificationsSentReport', 'loadServicesReport', 'loadServicesByDealerReport']),

    async loadReports () {
      try {
        this.startPageLoading()
        await Promise.all([
          this.loadActiveCarsReport({
            chainId: this.chainId,
            dealerId: this.dealerId
          }),
          this.loadNextNotificationsReport({ dealerId: this.dealerId, chainId: this.chainId }),
          this.loadNotificationsSentReport({ dealerId: this.dealerId, chainId: this.chainId }),
          this.loadServicesReport({ dealerId: this.dealerId, initialTreatment: true, chainId: this.chainId }),
          this.loadServicesReport({ dealerId: this.dealerId, initialTreatment: false, chainId: this.chainId })
        ])
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    }
  },


  async created () {
    this.setPageTitle(this.$t('nav.reports'))
    await this.loadReports()
  }
}
</script>
